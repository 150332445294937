import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import { setBaseUrl } from "@util/helper";

import { Maybe, SanityRegion, Query, SanitySeo } from "@graphql-types";

interface Props {
  seoData?: SanitySeo | null;
  post?: boolean;
  overwriteTitle?: string;
  overwriteImage?: string;
  overwriteDescription?: string;
  slug?: string;
  alternateRegions?: (Maybe<SanityRegion> | undefined)[];
  currentIban: any;
}

const SEO = (props: Props) => {
  const { sanitySiteConfig }: Query = useStaticQuery(graphql`
    query defaultSEOQuery {
      sanitySiteConfig {
        defaultSEO {
          ...sanitySeo
        }
      }
    }
  `);

  const { alternateRegions, slug, currentIban } = props;
  const urlString = setBaseUrl(slug, currentIban as string);
  const oragniseLang = () => {
    const filterCurrentRegion = alternateRegions?.filter(region => region?.iban === currentIban);
    const hrefLang =
      filterCurrentRegion && filterCurrentRegion[0] && filterCurrentRegion[0].hrefLang;
    return { currentLanguage: hrefLang ? hrefLang.substring(0, 2) : "en" };
  };

  const { currentLanguage } = oragniseLang();

  return (
    <div className="seo">
      <Helmet
        title={
          props.overwriteTitle ||
          props.seoData?.pageTitle ||
          (sanitySiteConfig?.defaultSEO?.pageTitle as string)
        }
      >
        {" "}
        {alternateRegions &&
          alternateRegions.length > 0 &&
          alternateRegions.map(alt => {
            if (alt == null) {
              return null;
            }

            return (
              <link
                key={alt.iban}
                rel="alternate"
                hrefLang={alt.hrefLang as string}
                href={setBaseUrl(slug, alt.iban as string)}
              />
            );
          })}
        {currentIban === process.env.GATSBY_DEFAULT_IBAN_REGION && (
          <link rel="alternate" hrefLang="x-default" href={urlString} />
        )}
        <meta
          name="description"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            (sanitySiteConfig?.defaultSEO?.pageDescription as string)
          }
        />
        <meta
          name="keywords"
          content={
            props.seoData?.pageKeyWords || (sanitySiteConfig?.defaultSEO?.pageKeyWords as string)
          }
        />
        <html lang={currentLanguage} />
        <meta
          property="og:title"
          content={
            props.overwriteTitle ||
            props.seoData?.pageTitle ||
            (sanitySiteConfig?.defaultSEO?.pageTitle as string)
          }
        />
        <meta property="og:type" content={props.post ? `article` : `website`} />
        <meta property="og:url" content={urlString} />
        <meta
          property="og:image"
          content={
            props.seoData?.ogImage?.asset?.url ||
            props.overwriteImage ||
            (sanitySiteConfig?.defaultSEO?.ogImage?.asset?.url as string)
          }
        />
        <meta
          property="og:description"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            (sanitySiteConfig?.defaultSEO?.pageDescription as string)
          }
        />
        {/* Twitter Card tags */}
        <meta
          name="twitter:card"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            (sanitySiteConfig?.defaultSEO?.pageDescription as string)
          }
        />
        <meta
          name="twitter:title"
          content={
            props.overwriteTitle ||
            props.seoData?.pageTitle ||
            (sanitySiteConfig?.defaultSEO?.pageTitle as string)
          }
        />
        <meta
          name="twitter:image"
          content={
            props.seoData?.ogImage?.asset?.url ||
            props.overwriteImage ||
            (sanitySiteConfig?.defaultSEO?.ogImage?.asset?.url as string)
          }
        />
        <meta property="twitter:site" content={urlString} />
        <meta
          name="twitter:description"
          content={
            props.seoData?.pageDescription ||
            props.overwriteDescription ||
            (sanitySiteConfig?.defaultSEO?.pageDescription as string)
          }
        />
        {props.seoData?.jsonLD && (
          <script type="application/ld+json">{props.seoData.jsonLD.code}</script>
        )}
      </Helmet>
    </div>
  );
};

export default SEO;
