import React from "react";
import { graphql, PageProps } from "gatsby";
import SEO from "@shared/seo";
import { Query, SanityPageWhereToBuyConnection, SanityStoreConnection } from "@graphql-types";
import Sections from "@shared/sections";
import { Hero } from "@global";
import { isSanityWhereToBuyList } from "@util/types";
import Layout from "@shared/layout";

interface Data extends Query {
  altRegions: SanityPageWhereToBuyConnection;
  allStoresByIban: SanityStoreConnection;
}

interface Props extends PageProps {
  data: Data;
}

export default function whereToBuyTemplate({ data }: Props) {
  if (data == null) return null;

  const { sanityPageWhereToBuy, altRegions, allStoresByIban } = data;
  const altRegionsOrganised = altRegions.nodes.map(alt => alt.region);

  if (sanityPageWhereToBuy == null) return null;

  const { seo, sections, slug, region, hero, headerDesign } = sanityPageWhereToBuy;

  return (
    <>
      <SEO
        seoData={seo}
        slug={slug?.current as string}
        alternateRegions={altRegionsOrganised}
        currentIban={region?.iban}
      />
      <Layout>
        <Hero data={hero} />

        {sections &&
          sections.map(section => {
            return (
              <Sections
                key={section?._key}
                data={section}
                extraData={
                  isSanityWhereToBuyList(section) && allStoresByIban && allStoresByIban.nodes
                }
              />
            );
          })}
      </Layout>
    </>
  );
}

export const query = graphql`
  query WhereToBuyPage($slug: String, $iban: String) {
    sanityPageWhereToBuy(slug: { current: { eq: $slug } }, region: { iban: { eq: $iban } }) {
      seo {
        ...sanitySeo
      }
      hero {
        ...sanityHero
      }
      sections {
        ...sanitySections
        ...sanityWhereToBuyList
      }
      title
      region {
        iban
      }
      slug {
        current
      }
      headerDesign
    }
    altRegions: allSanityPageWhereToBuy(
      filter: { slug: { current: { eq: $slug } }, isHidden: { ne: true } }
    ) {
      nodes {
        region {
          ...sanityRegion
        }
      }
    }
    allStoresByIban: allSanityStore(
      filter: { region: { iban: { eq: $iban } } }
      sort: { fields: order, order: DESC }
    ) {
      nodes {
        ...sanityStore
        order
      }
    }
  }
`;
